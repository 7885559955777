@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overscroll-behavior: none;
  scroll-behavior: smooth;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* font */
.palanquin-light {
  font-family: "Palanquin", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.palanquin-regular {
  font-family: "Palanquin", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.palanquin-medium {
  font-family: "Palanquin", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.palanquin-semibold {
  font-family: "Palanquin", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.palanquin-bold {
  font-family: "Palanquin", sans-serif;
  font-weight: 700;
  font-style: normal;
}
